import React, { useCallback, useRef, useState } from "react"
import { Helmet } from "react-helmet"

import favicon from "../images/favicon.png"
import transactions from "../images/mark-transactions.gif"

const formatMoney = (amount) => {
  return new Intl.NumberFormat("sv-SE", { style: "currency", currency: "SEK" }).format(amount)
}

const saveRegexp = /spara|avanza|isk/

const getCategory = ({ title, amount }) => {
  if (amount < 0) {
    if (saveRegexp.test(title.toLowerCase())) {
      return "SPARA"
    }

    return "UT"
  }

  return "IN"
}

const swedbankMarkedRegExp = /^.+?\n^.+?\n^.+?\n^.+?\n^.+?\d$/gm
const dataFromSwedbank = (content) => {
  const matches = content.match(swedbankMarkedRegExp)

  if (matches) {
    const formatted = matches.map((match) => {
      const split = match.split("\n")
      return {
        title: split[0],
        date: split[1],
        amount: split[3].replace(" ", "").replace(",", "."),
      }
    })

    return formatted
  }

  return []
}

const lfMarkedRegExp = /^.+?\n^.+?\n^.+?\d$/gm
const dataFromLf = (content) => {
  const matches = content.match(lfMarkedRegExp)

  if (matches) {
    const formatted = matches.map((match) => {
      const split = match.split("\n")

      return {
        title: split[1],
        date: split[0].split(" ")[0],
        amount: split[2].split("\t")[0].replace(" ", "").replace(",", "."),
      }
    })

    return formatted
  }

  return []
}

const icaMarkedRegExp = /[^\r\n]+/gm
const dataFromIca = (content) => {
  const matches = content.match(icaMarkedRegExp)

  if (matches) {
    const formatted = matches.map((match) => {
      const split = match.split("\t")

      return {
        title: split[1],
        date: split[0],
        amount: split[4].replace(" ", "").replace(",", ".").replace("kr", ""),
      }
    })

    return formatted
  }

  return []
}

const IndexPage = () => {
  const textAreaRef = useRef("")
  const [result, setResult] = useState([])
  const [textAreaFocus, setTextAreaFocus] = useState(false)
  const [bank, setBank] = useState("swedbank")
  const [resultBtnClicked, setResultBtnClicked] = useState(false)

  const parseTextareaContent = (e) => {
    const content = textAreaRef.current.value

    switch (bank) {
      case "swedbank":
        setResult(dataFromSwedbank(content))
        break
      case "lf":
        setResult(dataFromLf(content))
        break
      case "ica":
        setResult(dataFromIca(content))
        break
      default:
        setResult([])
        break
    }
  }

  const getSum = useCallback(() => {
    if (!result) return

    const positive = []
    const negative = []
    const savings = []

    const resultLenth = result.length
    for (let index = 0; index < resultLenth; index++) {
      const element = result[index]

      if (element.amount > 0) positive.push(parseFloat(element.amount))
      if (element.amount < 0) {
        if (saveRegexp.test(element.title.toLowerCase())) {
          savings.push(parseFloat(element.amount))
        } else {
          negative.push(parseFloat(element.amount))
        }
      }
    }

    return {
      positive: positive.reduce((a, b) => a + b, 0),
      negative: negative.reduce((a, b) => a + b, 0),
      savings: savings.reduce((a, b) => a + b, 0),
    }
  }, [result])

  return (
    <>
      <Helmet>
        <title>Månadskollen</title>
        <link rel="shortcut icon" type="image/png" href={favicon} />
      </Helmet>
      <div className="max-w-4xl mx-auto px-2">
        <h1 className="text-3xl font-semibold mt-6 mb-4 text-center">Månadskollen</h1>

        <div className="prose mx-auto mt-12">
          <p>
            Månadskollen är en superenkel tjänst där du kopierar och klistrar in transaktioner från
            din internetbanken för att snabb och enkelt få en summering kring din ekonomi.
          </p>
        </div>

        <div className="mt-6">
          <label>
            <span className="text-sm font-semibold">Kontoutdrag</span>
            <textarea
              className={`transition-all mt-1 block ${textAreaFocus ? "h-80" : "h-32"}`}
              onFocus={() => {
                setTextAreaFocus(true)
              }}
              onBlur={() => setTimeout(() => setTextAreaFocus(false), 1500)}
              ref={textAreaRef}
            ></textarea>
          </label>
          <label>
            <div className="text-sm font-semibold mt-2">Bank</div>
            <select className="mt-1" onChange={(e) => setBank(e.target.value)}>
              <option value="swedbank">Swedbank</option>
              <option value="lf">Länsförsäkringar</option>
              <option value="ica">ICA Banken</option>
            </select>
          </label>
          <button
            className="bg-green-300 w-full text-xl font-semibold py-4 mt-3 rounded-md"
            onClick={() => {
              parseTextareaContent()
              setResultBtnClicked(true)
              setTextAreaFocus(false)
            }}
          >
            Visa resultat
          </button>
        </div>

        {result.length === 0 && resultBtnClicked && (
          <>
            <div className="mt-12 text-center">
              <div className="prose text-center mx-auto">
                <h2>Något är fel med din data</h2>
                <p>
                  <em>Kontrollera så du kopierat rätt</em> 😔
                </p>
              </div>
            </div>
            <hr className="border-green-300 border w-1/3 mx-auto my-20" />
          </>
        )}

        {result.length > 0 && (
          <div className="w-full mx-auto">
            <h2 className="mt-12 text-center font-semibold text-4xl">Resultat</h2>

            <div className="flex justify-center mx-auto flex-col space-y-2 mt-8 text-center">
              <div className="text-green-600 font-bold text-xl">
                Inkomster: {formatMoney(getSum().positive)}
              </div>
              <div className="text-yellow-600 font-bold text-xl">
                Spara: {formatMoney(Math.abs(getSum().savings))}
              </div>
              <div className="text-red-600 font-bold text-xl">
                Utgifter: {formatMoney(getSum().negative)}
              </div>
              <div className="text-center mt-4 inline-block mx-auto border-t border-gray-500 pt-2">
                Resultat:{" "}
                {formatMoney(
                  getSum().positive - Math.abs(getSum().savings) - Math.abs(getSum().negative)
                )}
              </div>
            </div>

            <table className="mt-12 mx-auto table table-auto w-full">
              <thead>
                <tr style={{ textAlign: "left" }}>
                  <th>Namn</th>
                  <th>Summa</th>
                  <th className="text-center">Kategori</th>
                </tr>
              </thead>
              <tbody>
                {result.map((transaction, index) => {
                  return (
                    <tr
                      key={index}
                      className={`
                  ${getCategory(transaction) === "IN" ? "bg-green-200" : ""}
                  ${getCategory(transaction) === "UT" ? "bg-red-200" : ""}
                  ${getCategory(transaction) === "SPARA" ? "bg-yellow-200" : ""}
                  `}
                    >
                      <td>{transaction.title}</td>
                      <td>{formatMoney(transaction.amount)}</td>
                      <td className="text-center">{getCategory(transaction)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            <hr className="border-green-300 border w-1/3 mx-auto my-20" />
          </div>
        )}

        <div className="prose mx-auto mt-12">
          <h2>Så använder du tjänsten</h2>

          <ol>
            <li>Logga in på din internetbank</li>
            <li>Markera transaktioner du vill summera</li>
            <li>Kopiera</li>
            <li>Klistra in i "Kontoutdrag"</li>
            <li>Klicka "Visa resultat"</li>
          </ol>

          <img src={transactions} alt="" />

          <h3>Exempel data att testa med (Swedbank)</h3>
          <pre>
            Swish Playtomic
            <br />
            2021-09-08
            <br />
            2021-09-08
            <br />
            -109,90
            <br />
            8 277,16
            <br />
            Swish Playtomic
            <br />
            2021-09-08
            <br />
            2021-09-08
            <br />
            -139,90
            <br />
            8 387,06
            <br />
            Qliro
            <br />
            2021-09-08
            <br />
            2021-09-08
            <br />
            -1 872,20
            <br />8 526,96
            <br />
            Swish Playtomic
            <br />
            2021-09-08
            <br />
            2021-09-08
            <br />
            -500,00
            <br />
            8 277,16
            <br />
            ISK
            <br />
            2021-09-08
            <br />
            2021-09-08
            <br />
            7500,00
            <br />
            8 387,06
            <br />
            Lön
            <br />
            2021-09-08
            <br />
            2021-09-08
            <br />
            -1 872,20
            <br />
            8 526,96
            <br />
          </pre>

          <p>Vi sparar ingen data som du använder på sajten. Utan allt förblir anonymt</p>
        </div>

        <footer className="mt-20 mb-10 text-center">
          <div className="italic mb-4 w-3/4 mx-auto">
            Har du frågor, funderingar eller tips på förbättringar skriv till mig på Twitter (
            <a href="https://twitter.com/jarnesjo">@jarnesjo</a>)
          </div>
          &copy; {new Date().getFullYear()}. Skapad av{" "}
          <a className="text-gray-700" href="https://webready.se">
            Webready
          </a>
        </footer>
      </div>
    </>
  )
}

export default IndexPage
